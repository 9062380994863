export  const SITE_LOADING= 'SITE_LOADING';
export  const SITE_SUCCESS= 'SITE_SUCCESS';
export  const SITE_ERROR= 'SITE_ERROR';

export  const PROJECTS_LOADING= 'PROJECTS_LOADING';
export  const PROJECTS_SUCCESS= 'PROJECTS_SUCCESS';
export  const PROJECTS_ERROR= 'PROJECTS_ERROR';

export  const PROJECT_LOADING= 'PROJECT_LOADING';
export  const PROJECT_SUCCESS= 'PROJECT_SUCCESS';
export  const PROJECT_ERROR= 'PROJECT_ERROR';

export  const SEARCHED_PROJECTS_LOADING= 'SEARCHED_PROJECTS_LOADING';
export  const SEARCHED_PROJECTS_SUCCESS= 'SEARCHED_PROJECTS_SUCCESS';
export  const SEARCHED_PROJECTS_ERROR= 'SEARCHED_PROJECTS_ERROR';

export  const NEWS_LIST_LOADING= 'NEWS_LIST_LOADING';
export  const NEWS_LIST_SUCCESS= 'NEWS_LIST_SUCCESS';
export  const NEWS_LIST_ERROR= 'NEWS_LIST_ERROR';

export  const NEWS_LOADING= 'NEWS_LOADING';
export  const NEWS_SUCCESS= 'NEWS_SUCCESS';
export  const NEWS_ERROR= 'NEWS_ERROR';

export  const PUBLICATION_LOADING= 'PUBLICATION_LOADING';
export  const PUBLICATION_SUCCESS= 'PUBLICATION_SUCCESS';
export  const PUBLICATION_ERROR= 'PUBLICATION_ERROR';

export  const AWARD_LOADING= 'AWARD_LOADING';
export  const AWARD_SUCCESS= 'AWARD_SUCCESS';
export  const AWARD_ERROR= 'AWARD_ERROR';

export  const AWARDS_LOADING= 'AWARDS_LOADING';
export  const AWARDS_SUCCESS= 'AWARDS_SUCCESS';
export  const AWARDS_ERROR= 'AWARDS_ERROR';